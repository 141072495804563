<template>
  <div>
    <div class="bgBox">
      <el-carousel arrow="never" indicator-position="none">
        <el-carousel-item v-for="img in bglist" :key="img.id">
          <img class="img" :src="img.url" alt="" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="mainBox">
      <!-- <div class="mainTop"> -->
      <div class="mainauto">
        <div class="solutionBox">
          <div class="solutiontitle">
            <p class="maintitle">一体化云平台产品中心</p>
            <p class="subtitle">
              智慧一体化云平台专为提供企业管理解决方案<br />
              我们始终专注智慧管理领域，帮助您尽可能减少浪费，从而优化企业支出。
            </p>
          </div>
          <div class="flexmargin">
            <div class="solution_content" @mouseover="changeActive($event)" @mouseleave="removeActive($event)">
              <div class="solution_title">
                <img src="@/assets/index/solution_icon1.png" alt="" />设备管理
              </div>
              <div class="solution_main">
                一物一码，实现资产从采购、出入库、维修到处置的全生命周期管理。
              </div>
              <div class="solution_more">
                <router-link to="/devicemanage">了解更多>></router-link>
              </div>
            </div>

            <div class="solution_content" @mouseover="changeActive($event)" @mouseleave="removeActive($event)">
              <div class="solution_title">
                <img src="@/assets/index/solution_icon2.png" alt="" />物资管理
              </div>
              <div class="solution_main">
                统一管理承租单位、租金管理，施工单位、决策依据等可用户可提交投诉举报等。
              </div>
              <div class="solution_more">
                <router-link to="/materialmanage">了解更多>></router-link>
              </div>
            </div>
            <div class="solution_content" @mouseover="changeActive($event)" @mouseleave="removeActive($event)">
              <div class="solution_title">
                <img src="@/assets/index/solution_icon3.png" alt="" />教学管理
              </div>
              <div class="solution_main">
                满足学校培养过程管理、教学质量检查、教学工作评价、教学业绩评价、教学改革发展等战略性需求。
              </div>
              <div class="solution_more">
                <router-link to="/teachingmanage">了解更多>></router-link>
              </div>
            </div>
            <div class="solution_content" @mouseover="changeActive($event)" @mouseleave="removeActive($event)">
              <div class="solution_title">
                <img src="@/assets/index/solution_icon7.png" alt="" />实验室管理
              </div>
              <div class="solution_main">
                提供快捷方便的服务，及数据查询、统计为一体。包括了实验项目、仪器设备、易耗品、仪器、人员情况、仪器标定等。
              </div>
              <div class="solution_more">
                <router-link to="/laboratorymanage">了解更多>></router-link>
              </div>
            </div>
            <div class="solution_content" @mouseover="changeActive($event)" @mouseleave="removeActive($event)">
              <div class="solution_title">
                <img src="@/assets/index/solution_icon5.png" alt="" />采购管理
              </div>
              <div class="solution_main">
                通过采购申请、采购订货、退货、供应商管理、价格及供货信息管理及质量检验等功能综合运用的管理系统。
              </div>
              <div class="solution_more">
                <router-link to="/purchasemanage">了解更多>></router-link>
              </div>
            </div>
            <div class="solution_content" @mouseover="changeActive($event)" @mouseleave="removeActive($event)">
              <div class="solution_title">
                <img src="@/assets/index/solution_icon2.png" alt="" />OA
              </div>
              <div class="solution_main">
                OA系统包含流程管理、门户管理、知识管理、人事管理、项目管理、财务管理等多个功能模块。
              </div>
              <div class="solution_more">
                <router-link to="/workmanage">了解更多>></router-link>
              </div>
            </div>

            <div class="solution_content" @mouseover="changeActive($event)" @mouseleave="removeActive($event)">
              <div class="solution_title">
                <img src="@/assets/index/solution_icon4.png" alt="" />人力资源管理
              </div>
              <div class="solution_main">
                主要包括人事、招聘、培训、考勤、绩效、工资、后勤、餐饮、住宿、设施等管理。
              </div>
              <div class="solution_more">
                <router-link to="/manpowermanage">了解更多>></router-link>
              </div>
            </div>
            <div class="solution_content" @mouseover="changeActive($event)" @mouseleave="removeActive($event)">
              <div class="solution_title">
                <img src="@/assets/index/solution_icon6.png" alt="" />绩效管理
              </div>
              <div class="solution_main">
                绩效考核评价、绩效结果、绩效目标提升的持续循环过程、提升个人、部门和组织的绩效。
              </div>
              <div class="solution_more">
                <router-link to="/performancemanage">了解更多>></router-link>
              </div>
            </div>
            <div class="solution_content" @mouseover="changeActive($event)" @mouseleave="removeActive($event)">
              <div class="solution_title">
                <img src="@/assets/index/solution_icon8.png" alt="" />ZBL数据分析
              </div>
              <div class="solution_main">
                提供多源数据整合、报表设计、数据可视化、自助式BI分析、以及数据填报等功能，为管理者制定决策数据支撑。
              </div>
              <div class="solution_more">
                <router-link to="/datamanage">了解更多>></router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="industryBox">
        <div class="industrytitle">
          <div>智慧一体化云平台为每一个行业提高工作效率</div>
        </div>
        <div class="mainTop">
          <div class="mainauto">
            <div class="industrymain">
              <el-row type="flex" justify="space-between">
                <el-col :span="15">
                  <div class="grid-content bg-purple">
                    <img src="@/assets/index/industry_img4.png" alt="" />
                    <div class="industrymain_title">教育行业</div>
                  </div>
                </el-col>
                <el-col :span="8">

                  <div class="grid-content bg-purple">
                    <img src="@/assets/index/industry_img5.png" alt="" />
                    <div class="industrymain_title">医疗行业</div>
                  </div>
                </el-col>
              </el-row>
              <el-row>

                <el-col :span="24">
                  <div class="grid-content bg-purple">
                    <img src="@/assets/index/industry_img6.png" alt="" />
                    <div class="industrymain_title">园区/进销存/零售行业</div>
                  </div>
                </el-col>
              </el-row>

            </div>
          </div>
        </div>
      </div>
      <!-- </div> -->
      <div class="mainMiddle">
        <div class="mainauto brandBox">
          <div class="brandtitle">
            <div class="brand_maintitle">
              我们服务他们&nbsp;&nbsp;他们改变世界
            </div>
            <div class="brand_subtitle">
              WE SERVER THEM AND THEY CHANGE THE WORLD
            </div>
          </div>
          <ul class="brandmain">
            <li><img src="@/assets/brand/internet/logo_360.png" alt="" /></li>
            <li><img src="@/assets/brand/logo_ddmc.png" alt="" /></li>
            <li><img src="@/assets/brand/retail/logo_deli.png" alt="" /></li>
            <li><img src="@/assets/brand/retail/logo_redstar.png" alt="" /></li>
            <li><img src="@/assets/brand/retail/logo_mengniu.png" alt="" /></li>
            <li><img src="@/assets/brand/logo_loreal.png" alt="" /></li>
            <li><img src="@/assets/brand/internet/logo_jddj.png" alt="" /></li>
            <li><img src="@/assets/brand/internet/logo_jrtt.png" alt="" /></li>
            <li>
              <img src="@/assets/brand/property/logo_lianjia.png" alt="" />
            </li>
            <li><img src="@/assets/brand/logo_yijia.png" alt="" /></li>
            <li><img src="@/assets/brand/internet/logo_zhihu.png" alt="" /></li>
            <li><img src="@/assets/brand/retail/logo_bsd.png" alt="" /></li>
            <li>
              <img src="@/assets/brand/finance/logo_china_bank.png" alt="" />
            </li>
            <li>
              <img src="@/assets/brand/group/logo_wanda_dalian.png" alt="" />
            </li>
            <li><img src="@/assets/brand/medicine/logo_ymkd.png" alt="" /></li>
            <li>
              <img src="@/assets/brand/retail/logo_coca_cola.png" alt="" />
            </li>
          </ul>
        </div>
      </div>
      <div class="mainBottom">
        <div class="mainauto">
          <div class="brandtitle">
            <div class="brand_maintitle">产品与服务</div>
            <div class="brand_subtitle">PRODUCTS AND SERVICES</div>
          </div>
          <div class="bottommain">
            <div>
              <img src="@/assets/index/bottom_img1.png" alt="" />
              <div class="bottom_title">一站式业务接入</div>
              <div class="bottom_main">支付、结算、核算接入产品效率翻四倍</div>
            </div>
            <div>
              <img src="@/assets/index/bottom_img2.png" alt="" />
              <div class="bottom_title">一站式服务保障</div>
              <div class="bottom_main">
                在所有需求配置环节事前风险控制和质量控制能力
              </div>
            </div>
            <div>
              <img src="@/assets/index/bottom_img3.png" alt="" />
              <div class="bottom_title">一站式数据运营</div>
              <div class="bottom_main">沉淀产品接入效率和运营工作效率数据</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'video.js/dist/video-js.css'
import 'vue-video-player/src/custom-theme.css'
export default {
  name: "index",
  data() {
    return {
      bglist: [{ id: 0, url: require("@/assets/index/swdl.jpg") }],
      animation: false,

    };
  },
  methods: {
    changeActive($event) {
      $event.currentTarget.className = "solution_content_a";
    },
    removeActive($event) {
      $event.currentTarget.className = "solution_content";
    },
  },
};
</script>

<style scoped>
.bgBox {
  width: 100%;
  position: relative;
}

.bgBox>>>.el-carousel__container {
  height: 700px;
}

.bgTitle {
  min-width: 700px;
  position: absolute;
  color: #fff;
  left: 100px;
  bottom: 170px;
  z-index: 9;
  line-height: 50px;
}

.bgTitle>div:nth-child(1) {
  font-size: 48px;
}

.bgTitle>div:nth-child(2) {
  font-size: 14px;
}

.bgTitle a {
  display: block;
  width: 110px;
  height: 40px;
  font-size: 16px;
  border-radius: 6px;
  border: 1px solid #fff;
  text-align: center;
  line-height: 36px;
}

.mainTop {
  width: 100%;
  background: #f7f9fc;
}

.mainauto {
  width: 1200px;
  margin: 0 auto;
}

.solutionBox {
  padding-top: 56px;
}

.solutiontitle {
  width: 100%;
  text-align: center;
}

.maintitle {
  font-size: 38px;
  color: #404040;
}

.subtitle {
  padding: 40px 0;
  font-size: 18px;
  color: #666;
}

.img {
  width: 100%;
  height: 100%;
}

.solution_content {
  width: 376px;
  height: 276px;
  background: url("../assets/index/solution_bg.png") no-repeat;
  background-size: 100% 100%;
  color: #333;
  padding: 40px;
  transition: 0.5s;
  position: relative;
}

.solution_content img,
.solution_content_a img {
  width: 38px;
  vertical-align: middle;
  margin-right: 32px;
}

.solution_content_a {
  width: 376px;
  height: 276px;
  background: url("../assets/index/solution_bg.png") no-repeat;
  background-size: 100% 100%;
  color: #333;
  padding: 40px;
  transition: 0.5s;
  margin-top: -6px;
  position: relative;
}

.solution_title {
  font-size: 24px;
}

.solution_main {
  font-size: 20px;
  margin-top: 20px;
  padding-right: 18px;
}

.flexmargin {
  width: 100%;

  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}

.solution_content .solution_more {
  position: absolute;
  right: 34px;
  bottom: 34px;
  color: #669bee;
}

.solution_content_a .solution_more {
  position: absolute;
  right: 34px;
  bottom: 34px;
  color: #669bee;
}

.industrytitle {
  width: 100%;
  height: 90px;
  text-align: center;
  font-size: 28px;
  font-weight: bold;
  padding: 24px 0;
  background: url("../assets/index/title1_bg.png") no-repeat center;
  background-size: 15% 100%;
  margin: 16px 0 24px;
  letter-spacing: 2.2px;
}

.industrytitle img {
  display: inline-block;
}

.industrymain .grid-content {
  height: 399px;
  margin-bottom: 40px;
  position: relative;
}

.industrymain .industrymain_title {
  position: absolute;
  bottom: 24px;
  left: 38px;
  color: #fff;
  font-size: 36px;
}

.industrymain .grid-content img {
  width: 100%;
  height: 100%;
}

.industry_introduce {
  padding: 40px 0 0 56px;
  background: #fff;
}

.industrymain .el-row:nth-child(2n) .industry_introduce {
  padding: 40px 0 0 90px;
}

.introduce_main {
  margin-right: 90px;
  font-size: 24px;
}

.industrymain .el-row:nth-child(2n) .introduce_main {
  margin-right: 56px;
  font-size: 24px;
}

.introduce_title {
  font-size: 28px;
}

.line {
  width: 54px;
  height: 2px;
  background-color: #000;
  margin: 32px 0;
}

.mainMiddle {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

  padding: 56px 0;
}

.brandtitle {
  text-align: center;
}

.brand_maintitle {
  font-size: 28px;
}

.brand_subtitle {
  font-size: 20px;
  color: rgba(243, 243, 243, 0.6);
  line-height: 1px;
  position: relative;
  z-index: -1;
  font-family: "Georgia";
}

.brandmain {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  padding: 40px 0;
}

.brandmain li {
  width: calc(100% / 4);
  margin: 20px 0 25px;
}

.brandmain li img {
  max-width: 200px;
  margin: 0 auto;
  display: block;
}

.mainBottom {
  background: url("../assets/index/bottom_bg.png") no-repeat bottom;
  padding: 56px 0;
  background-size: 100% 30%;
}

.bottommain {
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  padding: 40px 0;
}

.bottommain>div {
  width: 20%;
  text-align: center;
  line-height: 30px;
}

.bottommain>div .bottom_title {
  font-size: 18px;
  color: #333;
  font-weight: bold;
}

.bottommain>div .bottom_main {
  font-size: 14px;
  color: #3f3f3f;
}

.bottommain>div img {
  width: 100px;
  height: 100px;
}
</style>